/************************************************
 * Created By nanyuantingfeng On 5/30/16 02:44.
 ************************************************/
// @i18n-ignore-all
export default [
  {
    id: '@financial',
    point: '@@menus',
    onload: () => [
      { id: 'financial', weight: 3, label: '财务管理' },
      {
        id: 'expense-manage',
        pId: 'financial',
        permissions: ['EXPENSE_MANAGE'],
        weight: 2,
        label: '报销单管理',
        actived: true,
        href: '/expense-manage',
        icon: 'menu-expense-manager'
      },
      {
        id: 'loan-manage',
        pId: 'financial',
        permissions: ['LOAN_MANAGE'],
        weight: 3,
        label: '借款管理',
        href: '/loan-manage',
        icon: 'menu-loan'
      },
      {
        id: 'requisition-manage',
        pId: 'financial',
        permissions: ['REQUISITION_MANAGE'],
        weight: 4,
        label: '申请管理',
        href: '/requisition-manage',
        icon: 'budget-person-type'
      },
      {
        id: 'budget-manage',
        pId: 'financial',
        powers: ['Budget'],
        permissions: ['BUDGET_MANAGE'],
        weight: 5,
        label: '预算控制',
        href: '/budget-manage',
        icon: 'budget-person-type'
      },
      {
        id: 'payments-manage',
        pId: 'financial',
        powers: ['Settlement'],
        permissions: ['SETTLEMENT_MANAGE'],
        weight: 6,
        label: '结算方式',
        href: '/payments-method'
      },
      {
        id: 'invoice-manage',
        pId: 'financial',
        powers: ['INVOICEMANAGE'],
        permissions: ['INVOICE_MANAGE'],
        weight: 7,
        label: '发票',
        href: '/invoice-manage'
      },
      {
        id: 'waitting-invoice-manage',
        pId: 'financial',
        powers: ['INVOICEMANAGE'],
        permissions: ['INVOICE_MANAGE'],
        weight: 8,
        label: '待开发票',
        href: '/waitting-invoice-manage'
      },
      {
        id: 'unite-invoice-manage',
        pId: 'financial',
        powers: ['INVOICEMANAGE'],
        permissions: ['INVOICE_MANAGE'],
        weight: 9,
        label: '统一开票',
        href: '/unite-invoice-manage'
      },
      {
        id: 'invoice-setting',
        pId: 'financial',
        powers: ['INVOICEMANAGE'],
        permissions: ['INVOICE_MANAGE'],
        weight: 10,
        label: '导入设置',
        href: '/invoice-setting'
      }
    ]
  },
  {
    point: '@@menus',
    async onload(app) {
      let [menus = [], { items = [] }] = await Promise.all([
        app.invokeService('@third-party-manage:get:my:thirdparty:list'),
        app.invokeService('@permission:getMinePrivilegePermissions')
      ])
      menus = menus.map((item, index) => {
        const { id, name } = item
        return {
          id: id,
          pId: 'financial',
          weight: 11 + index,
          label: name,
          actived: true,
          href: `/third-party-item/${id}`
        }
      })

      const formAuth = {
        custom: '/order-manage/',
        expense: '/expense-manage-privilege/',
        loan: '/loan-manage-privilege/',
        requisition: '/requisition-manage-privilege/'
      }

      items = items
        .map((item, index) => {
          const { id, name, active, formAuthorization } = item
          if (formAuth[formAuthorization]) {
            return {
              id: id,
              pId: 'financial',
              weight: 4,
              label: name,
              active,
              href: formAuth[formAuthorization] + id
            }
          }
        })
        .filter(v => v)

      return menus.concat(items)
    }
  }
]
